 // @ts-ignore
const app = Vue.createApp({})

import {Feedback, Person} from "./types";

 // @ts-ignore
import FeedbackComponent from './components/feedback.js';
 // @ts-ignore
import PageComponent from './components/Page.js';
 // @ts-ignore
import AdminComponent from './components/Admin';
 // @ts-ignore
import AdminPageComponent from './components/AdminPage';
 // @ts-ignore
import AdminFeedbackComponent from './components/AdminFeedback.js';
 // @ts-ignore
import ModalComponent from './components/Modal';
 // @ts-ignore
import AdminModalComponent from './components/AdminModal';
 // @ts-ignore
import LoginComponent from './components/Login';
 // @ts-ignore
import CheckmarkComponent from './components/Checkmark.js';

async function saveFeedback(feedback: Feedback) {
    await fetch("/api/feedback/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(feedback)
    });
}

app.component('Feedback', FeedbackComponent)
app.component('Page', PageComponent)
app.component('Admin', AdminComponent);
app.component('AdminPage', AdminPageComponent);
app.component('AdminFeedback', AdminFeedbackComponent);
app.component('Modal', ModalComponent);
app.component('AdminModal', AdminModalComponent);
app.component('Login', LoginComponent);
app.component('Checkmark', CheckmarkComponent);

app.mount("#app");
