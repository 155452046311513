<div class="pt-8 m-auto w-full m-8 w-full" v-if="loaded">
    <!--
        <div class="bg-gray-200 p-8 mt-4 w-full">
        <div class="bg-white px-8 py-4">
        <div class="text-2xl text-center py-2">Table of Contents</div>
        <div class="pt-1" v-for="page in pages">
        <div class="relative">
        <a v-bind:href="'#' + page[0]">{{page[1]}}</a> 
        </div>
        </div>
        </div>
        </div>
    -->
    <div class="px-4"> Show checked items: <input type="checkbox" v-model="show_checked"> </div>
    <div class="px-4"> Show hearts and lightbulbs: <input type="checkbox" v-model="show_heart_lightbulb"> </div>

    <div class="relative bg-gray-200 p-8 mt-4 w-full" v-for="page, index in pages">
        <AdminPage 
            :image_url="page_url(page[0])"
            :show_checked="show_checked"
            :show_heart_lightbulb="show_heart_lightbulb"
            :feedbacks="feedback_by_page[page[0]]"
            :zine_id="zine_id"
            :people="people"
            :number="index + 1"
            :id="page[0]"
            />
    </div>
    <div class="relative bg-gray-200 p-8 mt-4 w-full">
        <div class="w-3/4 m-auto">
            <h2 class='text-3xl text-center mb-8'> Overall Feedback </h2>
            <div v-for="feedback in feedback_by_page['overall']"> 
                <AdminFeedback 
                  v-bind:feedback="feedback"
                  v-bind:person="person(feedback)"
                  />
            </div>
        </div>
    </div>
</div>
