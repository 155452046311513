import template from "./AdminPage.html";
import { getPosition, saveFeedback, uuidv4, createPerson } from "./util";
import * as icons from "./icons.json";

export default {
  template: template,
  props: [
    "image_url",
    "feedbacks",
    "zine_id",
    "people",
    "id",
    "number",
    "show_checked",
    "show_heart_lightbulb",
  ],
  data: function () {
    return {
      hover_feedback: undefined,
      active_feedback: undefined,
      show_all_icons: false,
    };
  },
  methods: {
    person(feedback) {
      return this.people[feedback.person_id] || {};
    },
    hover_coords() {
      return this.icon_coords(this.hover_feedback);
    },
    toggle_all_icons() {
      this.show_all_icons = !this.show_all_icons;
    },
    icon(name) {
      const icon = icons[name];
      if (icon) {
        return icon.emoji;
      }
      // sometimes there's no icon because it's overall feedback
      return "";
    },
    icon_coords(feedback) {
      const img = this.$refs.image;
      if (!img) {
        return;
      }
      return getPosition(feedback, img.width, img.height);
    },
    click() {
      if (this.active_feedback) {
        return;
      }
      const element = event.target;
      this.active_feedback = {
        x: event.clientX - element.getBoundingClientRect().left,
        y: event.clientY - element.getBoundingClientRect().top,
        width: element.getBoundingClientRect().width,
        height: element.getBoundingClientRect().height,
        person_id: "julia",
        icon: "suggestion",
        zine_id: this.zine_id,
        zine_page: this.id,
      };
    },
    saveFeedback: async function (e, name) {
      e.preventDefault();
      console.log("saveFeedback", name);
      if (name) {
        // add bird emoji
        name = "🐦 " + name;
        const person = {
          name: name,
          id: uuidv4(),
          email: "",
        };
        const result = await createPerson(person);
        if (!result.ok) {
          alert("Error saving person");
        }
        this.active_feedback.person_id = person.id;
        // add person to people
        this.$parent.people[person.id] = person;
      }
      const ok = await saveFeedback(this.active_feedback);
      if (ok) {
        this.active_feedback.just_saved = true;
      }
      this.$parent.addFeedback(this.active_feedback);
      this.close();
    },
    close() {
      this.active_feedback = undefined;
    },
  },
};
