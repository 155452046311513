<div class="flex w-full">
  <div class="w-1/2 relative">
    <div class="relative">
      <div class="absolute top-2 right-2 text-2xl">{{number}}</div>
      <div class="absolute top-2 left-2 font-mono text-xs">{{id}}</div>
      <div class="absolute bottom-1 left-2 font-mono text-xs cursor-pointer" @click="toggle_all_icons()"> 
        <span v-if="show_all_icons">Hide</span>
        <span v-else>Show</span> icons
      </div>
      <img ref="image" class="w-full" v-bind:src="image_url" @click="click()" />
      <AdminModal
        v-if="active_feedback"
        v-bind:feedback="active_feedback"
        page_id="id"
      />
    </div>
    <div class="absolute" v-if="hover_feedback" v-bind:style="hover_coords()">
      <div
        class="absolute -left-4 -top-4 rounded-full w-8 h-8 bg-yellow-600 opacity-20 border border-yellow-800"
      ></div>
    </div>
    <div class="icons" v-if="show_all_icons">
      <template v-for="feedback in feedbacks">
        <div class="absolute" v-bind:style="icon_coords(feedback)">
          <div
             v-if="feedback.icon == 'heart' || feedback.icon == 'lightbulb'"
             class="absolute -left-4 -top-4 rounded-full w-8 h-8  opacity-60"
          > 
            <span v-html="icon(feedback.icon)"></span>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div class="w-1/2 pl-4 overflow-scroll" style="max-height: 600px;">
    <div v-for="feedback in feedbacks">
      <AdminFeedback
        v-if="(show_checked || !feedback.checked) && (show_heart_lightbulb || !(feedback.icon == 'heart' || feedback.icon == 'lightbulb'))"
        @mouseenter="hover_feedback = feedback"
        @mouseleave="hover_feedback = undefined"
        v-bind:feedback="feedback"
        v-bind:person="person(feedback)"
      />
    </div>
  </div>
</div>
